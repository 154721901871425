import "../static/logIn.css"
import swal from 'sweetalert'
import * as userApi from '../shared/api/userApi'
import React from 'react'

class LogInDynamo extends React.Component {
    state = {
        // username: 'divana',
        // password: '123456',

        username: '',
        password: ''
        // username: 'niceloop',
        // password: '123456'
    }

    handleOnchangeInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }) 
    }



    logIn = () => {

        const { username, password } = this.state

        if (username === '' || password === '') {
            return swal('เกิดข้อผิดพลาด', 'กรุณากรอกข้อมูลให้ครบถ้วน', 'error')
        }

        userApi.logInDynamo({ username, password },
            (data) => {
                window.location.href = '/'
            }, true)

    }

    render() {
        const { username, password } = this.state
        return (
            <div className="LoginForm" style={{ backgroundImage: `url(${require('../static/images/blur-26347-27038-hd-wallpapers.jpg')})` }}>
                <div className="row m-0">
                    <div className="col-sm-12 col-md-8 offset-md-2 col-lg-4 offset-lg-4">
                        <div >
                            <div style={{ marginTop: 60 }}></div>
                            <div className="login-form">
                                <div className="main-div" >
                                    <div className="panel">
                                        <h2>Niceloop User Satisfaction</h2>
                                        <p>Please enter your Username and Password</p>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="username" value={username} onChange={this.handleOnchangeInput} placeholder="Username" />
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control" name="password" value={password} onChange={this.handleOnchangeInput} placeholder="Password" />
                                    </div>
                                    <button onClick={this.logIn} className="btn btn-primary">Sign In</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LogInDynamo