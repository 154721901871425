import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Route, Switch, withRouter } from "react-router-dom";
// import MainEvaluate from './MainEvaluate'
import Transactions from './transaction'
import ThankYou from './thankyou'
import EvaluateList from '../pages/EvaluateList'
// import { connect } from 'react-redux'
import SatisfyForm from './SatisfyForm'

class MainContent extends React.Component {

    show = () => {
        // debugger
        const { user } = this.props
        if (_.isEmpty(user)) {
            return false
        }
        return true
    }

    render() {
        // const { match } = this.props
        return (

            <div>
                {this.show() &&
                    <Switch>
                        <Route path='/thankyou' render={(props) => <ThankYou {...props} />} />
                        <Route path='/transactions' render={(props) => <Transactions {...props} />} />
                        <Route path='/evaluate/:refId/:table/:mode/:orderId' render={(props) => <SatisfyForm {...props} />} />
                        <Route path='/evaluate/:refId/:table/:mode' render={(props) => <SatisfyForm {...props} />} />
                        <Route exact path='/' render={(props) => <EvaluateList {...props} />} />

                        {/* <Route exact path='/:readOnly' render={(props) => <MainEvaluate {...props} />} /> */}
                        {/* <Route exact path='/' render={(props) => <MainEvaluate {...props} />} /> */}

                    </Switch>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}


export default withRouter(connect(mapStateToProps)(MainContent))