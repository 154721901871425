import React from 'react'
import Highcharts from 'highcharts';
import _ from 'lodash'
require('highcharts/modules/exporting')(Highcharts);

class SummaryScoreChart extends React.Component {
    componentDidMount = () => {
        // debugger
        // if (this.chart) {
        //     this.chart.destroy()
        // }
        this.makeChart(this.props)

    }
    componentWillReceiveProps(nextProps) {
        // debugger
        // if (this.chart) {
        //     this.chart.destroy()
        // }
        this.makeChart(nextProps)
    }

    makeChart = (props) => {

        let scores = []

        _.times(8, index => {
            let score = checkNaN(props[`avgRate${index + 1}`])
            scores.push({ y: parseFloat(score), color: getBarColor(score) })
        })
        this.chart = Highcharts.chart('container', {
            chart: {
                type: 'bar'
            },
            title: {
                text: 'Score Summary'
            },
            // subtitle: {
            //     text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
            // },
            xAxis: {
                categories: ['Staff Friendliness', 'Atmosphere', 'Promptness of Service', 'Variety of Food', 'Quality of Food', 'Value for Money', 'Cleanliness', 'Overall Satisfaction'],
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                max: 5,
                title: {
                    text: 'Score (Max 5)',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' Scores'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            // legend: {
            //     layout: 'vertical',
            //     align: 'right',
            //     verticalAlign: 'top',
            //     x: -40,
            //     y: 80,
            //     floating: true,
            //     borderWidth: 1,
            //     backgroundColor: ((Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'),
            //     shadow: true
            // },
            credits: {
                enabled: false
            },
            series: [{
                name: props.fetchTime,
                data: scores
                // data: [107, 31, 635, 203, 2]
                // data: [{ y: 1, color: 'red' }, 2, 3.3, 4, 5]

            }
            ]
        });
    }

    render() {
        return (
            <div id="container" style={{ width: '100%', height: 400 }}></div>
        )
    }
}

const checkNaN = (val) => {
    if (_.isNaN(val) || val === '-NaN' || val === 'NaN') {
        return 0
    }

    return val
}

const getBarColor = (value) => {

    if (value < 3) {
        return 'red'
    }
    if (value < 4) {
        return 'orange'
    }
    if (value >= 4) {
        return 'green'
    }

}

export default SummaryScoreChart