import { connect } from 'react-redux'
import * as userApi from '../shared/api/userApi'
import swal from 'sweetalert'
import React from 'react';
import { Link, withRouter } from "react-router-dom";
// import { History } from "react-router";
import MediaQuery from 'react-responsive';
import { bindActionCreators } from 'redux'


const logOut = () => {
    swal({
        title: "Log out",
        text: "Are you sure ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
        .then((logout) => {
            if (logout) {
                userApi.logoutDynamo(true)
            }
        });
}

const Navbar = (props) => {
    const { user, router, history, location, actions } = props
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <MediaQuery maxDeviceWidth={800}>
                {location.pathname !== '/' &&
                    <a class="btn btn-link btn-xs"
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            history.goBack()
                        }}
                        href="#" style={{ float: 'left', display: 'inline-block', fontSize: '1.7rem', color: 'white' }}><i class="fas fa-angle-left"></i></a>
                }
            </MediaQuery>
            <a className="navbar-brand" style={{ color: 'white', fontSize: '1.7rem' }}>{user.shopName || ''}</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                    {
                        !user.isManager &&
                        <PostLink id="/" title="Evaluate" router={router} history={history} />
                    }
                    <PostLink id="/transactions" title="Transactions" history={history} />
                    {/* <PostLink id="/thankyou" title="Thankyou" history={history} /> */}

                    <li className="nav-item">
                        <a href="#" onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            window.location.reload(true)
                        }}>
                            <span className="nav-link" data-toggle="collapse" data-target="#navbarNavDropdown" style={{ fontSize: '1.7rem' }}>Reload (Clear Cache)</span>
                        </a>
                    </li>

                    <li className="nav-item">
                        <a href="#" className="nav-link"
                            style={{ fontSize: '1.7rem' }}
                            // style={{ color: 'red' }}
                            onClick={
                                (e) => {
                                    e.preventDefault()
                                    logOut()
                                }
                            }>Log out</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

const PostLink = (props) => {
    const { id, title, onClick } = props
    return (
        <li className="nav-item">
            <Link to={id} >
                <span className="nav-link" data-toggle="collapse" data-target="#navbarNavDropdown" style={{ fontSize: '1.7rem' }}>{title}</span>
            </Link>
        </li>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

// function mapDispatchToProps(dispatch) {
//     let actions = bindActionCreators({ ...operationAction }, dispatch)
//     return {
//         actions
//     }
// }

export default withRouter(connect(mapStateToProps)(Navbar))
