import React from 'react';
import _ from 'lodash'
import documentClient from '../shared/aws/dynamoDb'
import swal from 'sweetalert'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as operationAction from '../shared/redux/actions/operationActions'
import DatePickerModal from './DatePickerModal'
import SummaryCard from './SummaryCard'
import MenuTable from './MenuTable'
import SummaryScoreChart from './SummaryScoreChart'
import MediaQuery from 'react-responsive';

const dateModeStyle = { width: '35%' }

class Transaction extends React.Component {

    state = {
        transactions: [],
        date: moment().format('YYYY-MM-DD'),
        dateObj: Date.now(),
        filterMode: 'date',
        avgRate1: 0,
        avgRate2: 0,
        avgRate3: 0,
        avgRate4: 0,
        avgRate5: 0,
        avgRate6: 0,
        avgRate7: 0,
        avgRate8: 0,
        summaryMode: 'chart',
        showMenu: false,
        showMenuHolder: {}, //TODO:
        fetchTime: moment().format('DD MMM YYYY')

    }

    componentDidMount() {
        this.fetchTransaction()
    }

    fetchTransaction = (forceDate) => {
        const { user } = this.props
        const { filterMode } = this.state
        let date = forceDate || this.state.date
        let param = {
            TableName: "CustomerSatisfactionTx",
            IndexName: 'customerId-timestamp-index',
            KeyConditionExpression: "customerId = :customerId and begins_with(#timestamp, :timeFilter)",
            FilterExpression: 'attribute_exists(#dataAttr)',
            ExpressionAttributeValues: {
                ":customerId": user.customerId,
                ":timeFilter": date
            },
            ExpressionAttributeNames: {
                '#timestamp': 'timestamp',
                '#dataAttr': 'data',

            },
        };
        let self = this
        documentClient.query(param, (err, data) => {
            if (err) {
                swal('เกิดข้อผิดพลาด', err.message, 'error')
            } else {
                let totalRate1 = 0
                let totalRate2 = 0
                let totalRate3 = 0
                let totalRate4 = 0
                let totalRate5 = 0
                let totalRate6 = 0
                let totalRate7 = 0
                let totalRate8 = 0

                _.forEach(data.Items, (item) => {
                    totalRate1 += item.data.rate1
                    totalRate2 += item.data.rate2
                    totalRate3 += item.data.rate3
                    totalRate4 += item.data.rate4
                    totalRate5 += item.data.rate5
                    totalRate6 += item.data.rate6
                    totalRate7 += item.data.rate7
                    totalRate8 += item.data.rate8

                })

                let fetchTime = moment(date)
                if (filterMode === 'date') {
                    fetchTime = fetchTime.format('DD MMM YYYY')
                }
                if (filterMode === 'month') {
                    fetchTime = fetchTime.format('MMM YYYY')
                }
                if (filterMode === 'year') {
                    fetchTime = fetchTime.format('YYYY')
                }

                self.setState({
                    transactions: data.Items,
                    avgRate1: (totalRate1 / data.Items.length).toLocaleString(),
                    avgRate2: (totalRate2 / data.Items.length).toLocaleString(),
                    avgRate3: (totalRate3 / data.Items.length).toLocaleString(),
                    avgRate4: (totalRate4 / data.Items.length).toLocaleString(),
                    avgRate5: (totalRate5 / data.Items.length).toLocaleString(),
                    avgRate6: (totalRate6 / data.Items.length).toLocaleString(),
                    avgRate7: (totalRate7 / data.Items.length).toLocaleString(),
                    avgRate8: (totalRate8 / data.Items.length).toLocaleString(),

                    fetchTime
                })
            }
        });
    }

    viewTransaction = (tx) => {
        const { actions, history } = this.props
        actions.viewTransaction(tx)
        history.push(`/evaluate/${tx.refId}/${tx.data.table}/readOnly`)
    }

    dateObjToString = (date) => {
        let month = date.getMonth() + 1
        if (month < 10) {
            month = '0' + month
        }
        const dateString = `${date.getFullYear().toString()}-${month.toString()}-${date.getDate() < 10 ? ('0' + date.getDate().toString()) : date.getDate().toString()}`
        return dateString
    }
    saveDates = (dateObj) => {
        const date = this.dateObjToString(dateObj)
        this.setState({
            dateObj,
            date
        })

        this.fetchTransaction(date)
    }

    getFilterInput = () => {
        const { filterMode, date } = this.state
        if (filterMode === 'date') {
            return (
                <div class="input-group" style={{ width: 200 }} onClick={() => window.$("#dateButton").click()} >
                    <input type="text" value={`${moment(date).format('DD/MM/YYYY')}`} name="demo1" className="form-control" disabled />
                    <div class="input-group-append">
                        <div class="input-group-text" >
                            <i class="fa fa-calendar" aria-hidden="true" style={{ color: 'blue' }}></i>
                        </div>
                    </div>
                </div>
            )
        }
        else if (filterMode === 'month') {
            return (
                <input className="form-control"
                    value={date}
                    type='month'
                    style={{ minWidth: 100 }}
                    onChange={e => this.handleSetMonth(e)}
                />
            )
        }
        else if (filterMode === 'year') {
            return (
                <select className="form-control"
                    onChange={e => {
                        if (e.target.value === "") return
                        this.handleSetMonth(e)
                    }} >
                    <option value="">Select Year</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                </select>
            )
        }
    }
    handleSetMonth = (e) => {
        this.fetchTransaction(e.target.value)
        this.setState({ date: e.target.value })
    }
    getFilterButtons = () => {
        const { filterMode } = this.state
        return (
            <div className="btn-group btn-group-toggle" data-toggle="buttons" style={{ height: 38 }}>
                <ModeButton mode="date" filterMode={filterMode} onClick={this.changeDateMode} />
                <ModeButton mode="month" filterMode={filterMode} onClick={this.changeDateMode} />
                <ModeButton mode="year" filterMode={filterMode} onClick={this.changeDateMode} />
            </div>
        )
    }
    changeDateMode = (mode) => {

        this.setState({
            filterMode: mode,
            dateObj: Date.now()
        })
    }
    setSummaryMode = (mode) => {
        this.setState({
            summaryMode: mode
        })
    }

    isShowMenu = (refId) => {
        const { showMenu, showMenuHolder } = this.state
        if (showMenu) {
            return true
        }
        if (showMenuHolder[refId]) {
            return true
        }
        return false
    }
    setShowMenu = (e, refId) => {
        e.preventDefault()
        e.stopPropagation()
        const { showMenuHolder } = this.state
        let tmp = _.cloneDeep(showMenuHolder)
        if (tmp[refId]) {
            delete tmp[refId]
        }
        else {
            tmp[refId] = true
        }

        this.setState({
            showMenuHolder: tmp
        })
    }

    render() {
        const {
            transactions, dateObj,
            avgRate1, avgRate2, avgRate3, avgRate4,
            avgRate5, avgRate6, avgRate7, avgRate8,
            showMenu, fetchTime, summaryMode, showMenuHolder } = this.state
        return (
            <div className="row m-0">
                <div className="col-md-12  col-lg-8 offset-lg-2">
                    <div>

                        <div style={{ margin: 10 }}><h3>Transactions</h3>
                            <div className="row m-0" >
                                <div className="col-sm-auto" style={{ padding: 0, paddingRight: 10 }}>
                                    {this.getFilterButtons()}
                                </div>
                                <div className="col-sm-auto" style={{ padding: 0, paddingRight: 10 }}>
                                    {this.getFilterInput()}
                                </div>
                                <div class="align-self-center" >
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" checked={showMenu} onClick={() => this.setState({ showMenu: !showMenu, showMenuHolder: {} })} />
                                        <label class="form-check-label" for="exampleCheck1">Show Menu</label>
                                    </div>

                                </div>
                            </div>
                            <div style={{ marginTop: 15 }}>Result for: <span style={{ fontWeight: 'bold' }}>{fetchTime}</span></div>
                            <div style={{ paddingBottom: 10 }}></div>
                            <ul class="nav nav-tabs" >
                                <li class="nav-item">
                                    <a class={`nav-link ${summaryMode === 'chart' ? 'active' : ''}`} href="#" onClick={(e) => {
                                        e.preventDefault()
                                        this.setSummaryMode('chart')
                                    }}>Chart</a>
                                </li>
                                <li class="nav-item">
                                    <a class={`nav-link ${summaryMode === 'card' ? 'active' : ''}`} href="#" onClick={(e) => {
                                        e.preventDefault()
                                        this.setSummaryMode('card')
                                    }}>Card</a>
                                </li>
                            </ul>
                            {
                                summaryMode === 'chart' ?
                                    <SummaryScoreChart  {...this.state} fetchTime={fetchTime} /> :
                                    <div>
                                        <div style={{ paddingBottom: 10 }}></div>
                                        <div className="row row-eq-height m-0" >
                                            <SummaryCard title={`${checkNaN(avgRate1)}/5`} subtitle={`Staff Friendliness`} />
                                            <SummaryCard title={`${checkNaN(avgRate2)}/5`} subtitle={`Atmosphere`} />
                                            <SummaryCard title={`${checkNaN(avgRate3)}/5`} subtitle={`Promptness of Service`} />
                                            <SummaryCard title={`${checkNaN(avgRate4)}/5`} subtitle={`Variety of Food`} />
                                        </div>
                                        <div className="row row-eq-height m-0 " >
                                            <SummaryCard title={`${checkNaN(avgRate5)}/5`} subtitle={`Quality of Food`} />
                                            <SummaryCard title={`${checkNaN(avgRate6)}/5`} subtitle={`Value for Money`} />
                                            <SummaryCard title={`${checkNaN(avgRate7)}/5`} subtitle={`Cleanliness`} />
                                            <SummaryCard title={`${checkNaN(avgRate8)}/5`} subtitle={`Overall Satisfaction`} />
                                        </div>
                                    </div>
                            }

                            {
                                _.map(transactions, (detail, index) => {
                                    let tx = detail.data
                                    let age = moment().diff(moment(tx.birthDate), 'years')
                                    return (
                                        <div onClick={() => this.viewTransaction(detail)}
                                            key={`tx${index}`}
                                            className="card"
                                            style={{ marginTop: index === 0 ? 15 : 30, backgroundColor: '#eaeff7' }}>
                                            <div style={{ margin: 10 }}>
                                                <div className="row justify-content-between m-0">
                                                    <div ><span><h5 style={{ color: 'blue' }}>{tx.name !== 'none' ? tx.name : 'Unknow'}</h5></span></div>
                                                    <div><span><h5 >#{detail.data && detail.data.table}</h5></span></div>
                                                    <div>
                                                        <h5 >{moment(detail.timestamp).format('HH:mm @ DD/MM/YYYY')}</h5></div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div style={{ margin: 10 }}>
                                                {/* <div className="row justify-content-between m-0" style={{ paddingTop: 15, paddingLeft: 15, paddingRight: 15 }}>
                                                    <div><span style={{ fontWeight: 'bold', color: 'blue' }}>{tx.name !== 'none' ? tx.name : 'Unknow'}</span></div>
                                                    <div><span><i class="fa fa-phone" aria-hidden="true" style={{ color: 'blue' }}></i> {tx.mobile.inputNumber}</span></div>
                                                </div> */}
                                                <div className="row justify-content-between m-0" style={{ paddingTop: 15, paddingLeft: 15, paddingRight: 15 }}>
                                                    <div><span>{tx.gender}</span></div>
                                                    {/* <div><span>{tx.mobile.countryData.name}</span></div> */}
                                                    <div><span>{tx.nationality}</span></div>
                                                    <div>
                                                        {tx.birthDate !== 'none' && <div>{_.isNaN(age) ? '' : <span>Age {age} <small style={{ color: 'grey' }}>({moment(tx.birthDate).format('DD/MM/YYYY')})</small></span>}</div>}
                                                    </div>
                                                    <div>
                                                        <span><i class="fa fa-phone" aria-hidden="true" style={{ color: 'blue' }}></i> {tx.mobile}</span>
                                                    </div>
                                                </div>
                                                <div style={{ paddingTop: 30 }}></div>
                                                <MediaQuery minDeviceWidth={501}>
                                                    <table class="table table-striped">
                                                        <tbody>
                                                            <Rate label="Staff Friendliness" score={tx.rate1} />
                                                            <Rate label="Atmosphere" score={tx.rate2} />
                                                            <Rate label="Promptness of Service" score={tx.rate3} />
                                                            <Rate label="Variety of Food" score={tx.rate4} />
                                                            <Rate label="Quality of Food" score={tx.rate5} />
                                                            <Rate label="Value for Money" score={tx.rate6} />
                                                            <Rate label="Cleanliness" score={tx.rate7} />
                                                            <Rate label="Overall Satisfaction" score={tx.rate8} />
                                                        </tbody>
                                                    </table>
                                                </MediaQuery>

                                                <MediaQuery maxDeviceWidth={500}>
                                                    <div className="card">
                                                        <div style={{ margin: 10 }}>
                                                            <RateMobile label="Staff Friendliness" score={tx.rate1} />
                                                            <HR />
                                                            <RateMobile label="Atmosphere" score={tx.rate2} />
                                                            <HR />
                                                            <RateMobile label="Promptness of Service" score={tx.rate3} />
                                                            <HR />

                                                            <RateMobile label="Variety of Food" score={tx.rate4} />
                                                            <HR />

                                                            <RateMobile label="Quality of Food" score={tx.rate5} />
                                                            <HR />

                                                            <RateMobile label="Value for Money" score={tx.rate6} />
                                                            <HR />

                                                            <RateMobile label="Cleanliness" score={tx.rate7} />
                                                            <HR />

                                                            <RateMobile label="Overall Satisfaction" score={tx.rate8} />
                                                        </div>
                                                    </div>
                                                </MediaQuery>


                                            </div>
                                            {tx.sugFood !== '-' && tx.sugBeverage !== '-' && tx.sugOther !== '-' && <hr />}
                                            <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                                                {tx.sugFood !== '-' &&
                                                    <div className="row m-0" style={{ paddingTop: 15 }}>
                                                        <div className="col-sm-3 col-md-3">Food:</div>
                                                        <div className="col-sm-9 col-md-9"><span style={{ fontStyle: 'italic' }}>{tx.sugFood}</span></div>
                                                    </div>
                                                }
                                                {tx.sugBeverage !== '-' &&
                                                    <div className="row m-0" style={{ paddingTop: 15 }}>
                                                        <div className="col-sm-3 col-md-3">Beverage:</div>
                                                        <div className="col-sm-9 col-md-9"><span style={{ fontStyle: 'italic' }}>{tx.sugBeverage}</span></div>
                                                    </div>
                                                }
                                                {tx.sugOther !== '-' &&
                                                    <div className="row m-0" style={{ paddingTop: 15 }}>
                                                        <div className="col-sm-3 col-md-3">Order:</div>
                                                        <div className="col-sm-9 col-md-9"><span style={{ fontStyle: 'italic' }}>{tx.sugOther}</span></div>
                                                    </div>
                                                }
                                                {/* <a href="#" >{showMenuHolder[detail.refId] ? 'Show less' : 'Show more'}</a> */}
                                                <button onClick={(e) => this.setShowMenu(e, detail.refId)} type="button" class="btn btn-light">{showMenuHolder[detail.refId] ? 'Show less' : 'Show more'}</button>
                                                <div style={{ paddingBottom: 15 }}></div>
                                            </div>
                                            {this.isShowMenu(detail.refId) && detail.order && detail.order.items && <hr />}
                                            {
                                                this.isShowMenu(detail.refId) && detail.order && detail.order.items &&
                                                <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                                                    <MenuTable order={detail.order} />
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div >
                        <button id="dateButton" type="button" data-toggle="modal" data-target="#myModal" style={{ visibility: 'hidden' }}></button>
                        <DatePickerModal
                            saveDates={this.saveDates}
                            startDate={dateObj}
                        />
                    </div >
                </div>
            </div>
        );
    }
}

const getScoreName = (value) => {
    switch (value) {
        case 1:
            return 'Poor'
        case 2:
            return 'Fair'
        case 3:
            return 'Good'
        case 4:
            return 'Very Good'
        case 5:
            return 'Excellent'
    }
}

const checkNaN = (val) => {
    if (_.isNaN(val) || val === '-NaN' || val === 'NaN') {
        return 0
    }

    return val
}

const HR = () => {
    return <hr style={{ marginTop: 10, marginBottom: 10 }} />
}

const Rate = ({ label, score }) => {
    return (
        <tr>
            <td scope="row" style={{ width: '35%' }}>{label}</td>
            <td style={{ width: '65%' }}>
                <div className="row m-0">
                    <div>
                        {_.times(5, i => <span key={`star${i}`} className={`fa fa-star ${score >= (i + 1) ? 'checked' : ''}`} />)}
                    </div>
                    <div className="align-self-center">
                        <span style={{ paddingLeft: 15 }}><small style={{ color: 'grey', fontSize: '0.85em', fontStyle: 'italic' }}>{getScoreName(score)}</small></span>
                    </div>
                </div>
            </td>
        </tr>
    )
}

const RateMobile = ({ label, score }) => {
    return (
        <div>
            <div>{label}</div>
            <div>
                {_.times(5, i => <span key={`star${i}`} className={`fa fa-star ${score >= (i + 1) ? 'checked' : ''}`} style={i === 0 ? { marginLeft: 0 } : {}} />)}
                <span><small style={{ color: 'grey', fontSize: '0.6em', fontStyle: 'italic' }}>{getScoreName(score)}</small></span>
            </div>
        </div>
    )
}

const ModeButton = ({ mode, onClick, filterMode }) => {
    return (
        <label className={"btn btn-primary " + (filterMode === mode ? 'active' : '')} style={dateModeStyle} onClick={() => onClick(mode)}>
            <input type="radio" name="options" />{getModeName(mode)}
        </label>
    )
}
const getModeName = (mode) => {
    let name = 'วัน'
    switch (mode) {
        case 'month':
            name = 'เดือน'
            break
        case 'year':
            name = 'ปี'
            break
        default:
            break
    }

    return name
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ ...operationAction }, dispatch)
    return {
        actions,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transaction)