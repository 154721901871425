import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import LogIn from './pages/LogInDynamo';

import { createBrowserHistory } from "history";
import { Route, Switch, Router } from "react-router-dom";
import store from './shared/redux/store'
import { Provider } from "react-redux";
// import * as serviceWorker from './serviceWorker';


// ReactDOM.render(<App />, document.getElementById('root'));

const hist = createBrowserHistory();
var pathName = window.location.pathname;

const main = () => {
    ReactDOM.render(
        <Provider store={store}>
            <Router history={hist}>
                <Switch>
                    <Route path='/' render={(props) => <App {...props} />} history={hist} />
                </Switch>
            </Router>
        </Provider>
        ,
        document.getElementById("root")
    )
}

const checkLogIn = () => {
    const loggedIn = window.localStorage.getItem("username")
    return loggedIn
}


switch (pathName) {
    case '/':
        if (checkLogIn()) { //LOGGED IN ?
            main()
        }
        else {
            ReactDOM.render(<LogIn />, document.getElementById("root"));
        }
        break
    default:
        window.location.href = "/";
        break
}


// serviceWorker.unregister();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
// ReactDOM.render(<App />, document.getElementById('root'));
