import React from 'react';

const SummaryCard = ({ title, subtitle, style }) => {
    return (
        // <div className="col-md-3 col-sm-6" style={{ padding: 0 }}>
        <div className="card col-md-3 col-sm-6" style={{ padding: 0, ...style }}>
            <div className="card-body" style={{ padding: 10 }}>
                <center><span style={{ fontSize: 20, color: 'blue' }}>{title}</span></center>
                <center><span >{subtitle}</span></center>
            </div>
        </div>
        // </div>

    )
}

export default SummaryCard