export const satisfyForm = {
    head: 'Customer\'s feedback',
    name: 'Name',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    mobile: 'Mobile',
    birthDate: 'BirthDate',
    email: 'Email',
    rating: 'Rating',
    rate1: 'Staff Friendliness',
    rate2: 'Atmosphere',
    rate3: 'Promptness of Service',
    rate4: 'Variety of Food',
    rate5: 'Quality of Food',
    rate6: 'Value for Money',
    rate7: 'Cleanliness',
    rate8: 'Overall Satisfaction',
    suggestion: 'Suggestion',
    food: 'Food',
    beverage: 'Beverage',
    other: 'Other',
    nationality:'Nationality'


}