import React, { Component } from 'react';
import Container from './pages/Container'
import { BrowserRouter as Router } from "react-router-dom";
import './static/app.css'


class App extends Component {
  render() {
    const { history } = this.props

    return (
      <Router>
        <Container history={history} />
      </Router>
    );
  }
}

export default App;
