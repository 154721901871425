import * as type from './type'

export const viewTransaction = (data) => {

    return (dispatch, getState) => { 
        dispatch({
            type: type.VIEW_TRANSACTION,
            payload: data
        })
    };
};

export const setLanguage = (language) => {

    return (dispatch, getState) => {
        dispatch({
            type: type.SET_LANGUAGE,
            payload: language
        })
    };
};