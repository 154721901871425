import React, { Component } from 'react';
import Header from './Header'
import MainContent from './MainContent'
import Navbar from './navbar'


class Container extends Component {
    render() {
        const { history } = this.props

        return (
            <div>
                <Header />
                <Navbar history={history} />
                <MainContent />
            </div>
        );
    }
}

export default Container;
