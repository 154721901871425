import AWS from 'aws-sdk';

const API_KEY = 'AKIAJV7DLDUKL6BBXEEA'
const SECERT_KEY = '3B/yAPYf3d8JOexx9Aiaw8IAgAuE4f1GC6GkSeS/'

const documentClient = new AWS.DynamoDB.DocumentClient({
    region: 'ap-southeast-1',
    endpoint: 'dynamodb.ap-southeast-1.amazonaws.com',
    accessKeyId: API_KEY,
    secretAccessKey: SECERT_KEY
}) 

export default documentClient;
