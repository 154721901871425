export const satisfyForm = {
    head: 'แบบประเมินความคิดเห็นของลูกค้า',
    name: 'ชื่อ - นามสกุล',
    gender: 'เพศ',
    male: 'ชาย',
    female: 'หญิง',
    mobile: 'มือถือ',
    birthDate: 'วันเกิด',
    email: 'Email',
    rating: 'การให้คะแนน',
    rate1: 'ความเป็นมิตรของพนักงาน',
    rate2: 'บรรยากาศภายในร้าน',
    rate3: 'ความรวดเร็วในการให้บริการ',
    rate4: 'ความหลากหลายของเมนูอาหาร',
    rate5: 'คุณภาพของอาหาร',
    rate6: 'ความคุ้มค่า',
    rate7: 'ความสะอาด',
    rate8: 'ภาพรวมสำหรับความพึงพอใจ',
    suggestion: 'คำแนะนำ',
    food: 'อาหาร',
    beverage: 'เครื่องดื่ม',
    other: 'อื่นๆ',
    nationality:'สัญชาติ'



}