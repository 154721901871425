let firebase = require("firebase");
// require("firebase/firestore");

let config = {
  apiKey: "AIzaSyCTnEcbtpIuEV6WuSQiNiEisu92cyk7FlQ",
  authDomain: "backup-niceloop-v1.firebaseapp.com",
  databaseURL: "https://backup-niceloop-v1.firebaseio.com",
  projectId: "backup-niceloop-v1",
  storageBucket: "backup-niceloop-v1.appspot.com",
  messagingSenderId: "110209570441"
};

// firebase.initializeApp(config);
if (!firebase.apps.length) {
  firebase.initializeApp(config);
  // firebase.firestore().enablePersistence();
}

export const rootRef = firebase.database().ref();
export const customerSatisfactionRef = rootRef.child('customer_satisfaction')

//Fire Store
// export let fireStoreRootRef = firebase.firestore()
// export let fireStoreBranchsOverrideRef = fireStoreRootRef.collection("branchs_override")


// customerSatisfactionRef.on('value', (snapshot) => {
//   let department = snapshot.val();

//   dispatch({
//       type: type.DEPARTMENT_FETCH_LIST,
//       payload: department || {}
//   })
// })
