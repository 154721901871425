
import { combineReducers } from 'redux'
import transactions from './transactionsReducer'
import operations from './operationsReducer'
import user from './userReducer'



export default combineReducers({
    transactions,
    operations,
    user

})
