
import * as type from '../actions/type'

const reducer = (
    state = {
        viewTX: {},
        language: 'english'
    },
    action) => {
    switch (action.type) {
        case type.VIEW_TRANSACTION:
            return { ...state, viewTX: action.payload }
        case type.SET_LANGUAGE:
            return { ...state, language: action.payload }
        default:
            return state
    }
}

export default reducer