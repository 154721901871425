import React from 'react';
class Thank extends React.Component {
    state = {}
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div className="jumbotron text-xs-center" style={{ minHeight: '100vh' }}>
                {/* <div style={{ marginTop: '10%' }}></div> */}
                <center>
                    <div>
                        <h1 className="display-3">Thank You!</h1>
                        <div style={{ marginTop: 20 }}>
                            <p className="lead">ขอบคุณที่ใช้บริการค่ะ</p>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <p className="lead">คุณจะได้รับ Gift Voucher Code ทาง SMS สามารถนำไปแลกของรางวัลได้ที่ Cashier</p>
                        </div>
                        {/* <div style={{ marginTop: 100 }}>
                            <img src={require('../static/images/scanme.png')} width={400} height={400} />
                        </div> */}
                    </div>
                </center>
            </div>
        );
    }
}

export default Thank;