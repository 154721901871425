import * as firebaseRef from '../firebaseRef'
import { connect } from 'react-redux'
import randomString from "random-string";
import React from 'react'
import _ from 'lodash'
import swal from 'sweetalert'
import * as operationActions from '../shared/redux/actions/operationActions'
import { bindActionCreators } from 'redux'

class EvaluateList extends React.Component {
    state = {
        list: [],
    }

    componentDidMount() {
        const { history, user } = this.props
        if (user.isManager) {
            history.push('/transactions')
            return
        }
        this.fetchAvailableEvaluateList()
    }

    fetchAvailableEvaluateList = () => {
        const { user } = this.props
        // debugger
        firebaseRef.customerSatisfactionRef.child(user.customerId).on('value', (snapshot) => {
            let data = snapshot.val();
            let sorted = _.orderBy(data, ['startTime'], ['desc'])
            this.setState({
                list: sorted
            })
        })
    }

    manualEvaluate = () => {
        const { history } = this.props

        swal({
            text: 'Type Receipt number',
            content: "input",
            buttons: ['Cancel', 'Evaluate']
        })
            .then(manaulReceiptNo => {
                if (manaulReceiptNo === '' || manaulReceiptNo === null) {
                    return
                }
                let randomRefId = randomString({
                    length: 8,
                    numeric: true,
                    letters: false,
                    special: false
                });

                history.push(`/evaluate/${randomRefId}/Manual/Manual/${manaulReceiptNo}`)

            })


    }

    makeEvaluate = (data) => {
        const { history, actions } = this.props
        actions.viewTransaction({})
        let computedTableName = ''
        _.forEach(data.table, (char) => {
            if (char === "#") {
                return
            }
            computedTableName += char
        })
        history.push(`/evaluate/${data.refId}/${computedTableName}/new`)
    }

    clearList = () => {
        const { user } = this.props
        const { list } = this.state
        swal({
            title: "คุณต้องการลบรายการประเมินทั้งหมดหรือไม่ ?",
            text: "หากต้องการประเมินอีกครั้ง จะต้องทำการกดปุ่ม Print Preview อีกรอบ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    _.forEach(list, (line) => {
                        firebaseRef.customerSatisfactionRef.child(user.customerId).child(line.refId).remove()
                            .catch(err => {
                                alert(`Failed at RefId: ${line.refId}`)
                            })
                    })
                }
            });
    }


    render() {
        const { list } = this.state
        // const { history } = this.props
        return (
            <div style={{ margin: 10 }}>
                {/* <button onClick={() => history.goBack()}>GO BACK</button> */}

                <div className="row m-0">
                    <div className="col-md-8 offset-md-2">
                        <div className="row m-0 justify-content-between">
                            <div><h4>Evaluate List</h4></div>
                            <div>
                                <button type="button" className="btn btn-primary" onClick={this.manualEvaluate}>Manual</button>
                                <button style={{ marginLeft: 30 }} type="button" className="btn btn-link" onClick={this.clearList}>Clear</button>
                            </div>

                        </div>
                        {_.size(list) < 1 && <div style={{ marginTop: 40 }}>
                            <center><span style={{ fontSize: 30, color: 'lightgrey' }}>No available order to do yet.</span></center>
                        </div>}
                        <div style={{ marginTop: 48 }}></div>
                        {
                            _.map(list, (line, index) => {
                                return (
                                    <div key={`c${index}`} className="card" style={{ backgroundColor: 'lightgrey', marginTop: 10 }}>
                                        <div style={{ margin: 10 }}>
                                            <div className="row m-0 justify-content-between">
                                                <div>{line.table}</div>
                                                <div>
                                                    ฿{line.grandTotal.toLocaleString()}

                                                    <a href="#"
                                                        style={{ paddingLeft: 20 }}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            this.makeEvaluate(line)
                                                        }}>
                                                        Evaluate</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }


                    </div>
                </div>
            </div>

        );
    }
}



const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ ...operationActions }, dispatch)
    return {
        actions,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EvaluateList)