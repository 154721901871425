import logger from "redux-logger";
import ReduxThunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
// import InitMiddleware from '../redux/middleware/init.middleware'
import reducers from './reducers/rootReducer'

const store = createStore(
    reducers,
    applyMiddleware(
        ReduxThunk,
        logger,
        // InitMiddleware
    )
);

// debugger
// store.dispatch({
//     type: "FETCH_TRANSACTIONS",
//     payload: {}
// })

export default store