import _ from 'lodash'
import React from 'react'

class MenuTable extends React.Component {
    state = {}
    render() {
        const { order, table } = this.props
        return (
            <div>
                <table class="table table-bordered" style={{ backgroundColor: 'white' }}>
                    <thead>
                        <tr>
                            <th scope="col">{table || 'Name'}</th>
                            <th scope="col" style={{ textAlign: 'right' }}>Qty</th>
                            <th scope="col" style={{ textAlign: 'right' }}>Total Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(order.items, (item, index) => {
                            return (
                                <tr key={`tr${index}`}>
                                    <th scope="row">{item.name}</th>
                                    <td style={{ textAlign: 'right' }}>{item.quantity}</td>
                                    <td style={{ textAlign: 'right' }}>{item.discountedPrice.toLocaleString()}</td>
                                </tr>
                            )
                        })}
                        {_.map(order.summary, (service, index) => {
                            return (
                                <tr key={`tr${index}`}>
                                    <th scope="row" colspan="2" style={{ color: 'grey' }}>{service.key}</th>
                                    <td style={{ textAlign: 'right', color: 'grey' }}>{service.value.toLocaleString()}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <th scope="row" colspan="2">Grand Total</th>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{order.grandTotal.toLocaleString()}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        );
    }
}

export default MenuTable;