import * as userApi from '../shared/api/userApi'
import { connect } from 'react-redux'
import React from 'react';
class Header extends React.Component {
    state = {}

    componentDidMount() {
        const { dispatch } = this.props
        userApi.logInDynamo(null, (data) => {
            dispatch({
                type: "FETCH_LOGGED_IN_USER",
                payload: data
            })
        })
    }
    render() {
        return (
            <div></div>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Header)
