import _ from 'lodash'
import InputMask from 'react-input-mask';
import country_list from '../shared/exampleData/countries'
import Autocomplete from 'react-autocomplete'
import documentClient from '../shared/aws/dynamoDb'
import swal from 'sweetalert'
import moment from 'moment'
import { connect } from 'react-redux'
import MenuTable from './MenuTable'
import React from 'react';
// import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import * as operationActions from '../shared/redux/actions/operationActions'
import dialogs from '../shared/dialogs/index'
import { bindActionCreators } from 'redux'
import MediaQuery from 'react-responsive';


const initData = {
    readOnly: false,
    nationality: '',
    name: '',
    mobile: '',
    // mobile: {
    //     status: false,
    //     inputNumber: '',
    //     computedNumber: '',
    //     countryData: {
    //         areaCodes: null,
    //         dialCode: "66",
    //         iso2: "th",
    //         name: "Thailand (ไทย)",
    //         priority: 0
    //     }
    // },
    gender: 'Male',
    birthDate: '',
    sugFood: '',
    sugBeverage: '',
    sugOther: '',
    email: '',
    rate1: 3,
    rate2: 3,
    rate3: 3,
    rate4: 3,
    rate5: 3,
    rate6: 3,
    rate7: 3,
    rate8: 3,
    initKey: moment().toISOString(),
    // isTablet: detectmob()
}


function detectmob() {
    if (window.innerWidth <= 1200 && window.innerHeight <= 500) {
        return true;
    } else {
        return false;
    }
}
class SatisfyForm extends React.Component {

    state = initData

    componentDidMount() {
        const { match, viewTx } = this.props
        if (match.params.mode === 'readOnly') {
            this.setState({
                ...viewTx.data,
                readOnly: true,
                initKey: moment().toISOString()
            })
        }
        // this.deleteUnEvalueateData()
    }
    componentWillReceiveProps(nextProps) {
        const { match } = nextProps
        // this.deleteUnEvalueateData()
        if (match.params.mode === 'readOnly') {
            return
        }
        this.setState({ ...initData, readOnly: false })

    }

    setLanguage = () => {
        const { language, actions } = this.props

        if (language === 'english') {
            actions.setLanguage('thai')
        }
        else {
            actions.setLanguage('english')
        }
    }

    render() {
        const { readOnly } = this.state
        const { viewTx, match, language } = this.props
        let { table } = match.params
        let d = dialogs[language].satisfyForm
        return (
            <div className="row m-0">
                <div className="col-md-12">
                    <div>
                        <div className="container-fluid">
                            <br />
                            <div className="row m-0 justify-content-between">
                                <div className="align-self-center"><span style={{ fontWeight: 'bold', fontSize: 20 }}>{d.head}</span></div>
                                <div className="row m-0">
                                    <div>
                                        {/* <select class="form-control" value={language} onChange={(e) => actions.setLanguage(e.target.value)}>
                                            <option value="english">English</option>
                                            <option value="thai">Thai</option>
                                        </select> */}
                                        <button type="button" class="btn btn-link" onClick={this.setLanguage}>
                                            <i style={{ fontSize: 30 }} class="fas fa-language"></i>
                                        </button>

                                    </div>
                                    <div className="align-self-center">
                                        <span style={{ fontWeight: 'bold', fontSize: 20, color: 'blue', paddingLeft: 20 }}>{table || ""}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: 30 }}></div>
                            <Spraed d={d} handleInput={this.handleInput}
                                handleInputMobile={this.handleInputMobile}
                                handleSelectMobileCountry={this.handleSelectMobileCountry}
                                {...this.state}
                                setGender={this.setGender}
                                readOnly={readOnly}
                                handleInputNation={this.handleInputNation} />
                            < br />
                            <span style={{ fontWeight: 'bold', fontSize: 20 }}>{d.rating}</span>
                            <br />
                            {/* poor, fair, good , very good , excellent */}
                            <MediaQuery minDeviceWidth={501}>
                                <table className="table table-striped ">
                                    <tbody>
                                        <TrStar setRateScore={this.setRateScore} name="rate1" value={this.state["rate1"]} title={d.rate1} />
                                        <TrStar setRateScore={this.setRateScore} name="rate2" value={this.state["rate2"]} title={d.rate2} />
                                        <TrStar setRateScore={this.setRateScore} name="rate3" value={this.state["rate3"]} title={d.rate3} />
                                        <TrStar setRateScore={this.setRateScore} name="rate4" value={this.state["rate4"]} title={d.rate4} />
                                        <TrStar setRateScore={this.setRateScore} name="rate5" value={this.state["rate5"]} title={d.rate5} />
                                        <TrStar setRateScore={this.setRateScore} name="rate6" value={this.state["rate6"]} title={d.rate6} />
                                        <TrStar setRateScore={this.setRateScore} name="rate7" value={this.state["rate7"]} title={d.rate7} />
                                        <TrStar setRateScore={this.setRateScore} name="rate8" value={this.state["rate8"]} title={d.rate8} />
                                    </tbody>
                                </table>
                            </MediaQuery>

                            <MediaQuery maxDeviceWidth={500}>
                                <table className="table table-striped ">
                                    <tbody>
                                        <TrStarMobile setRateScore={this.setRateScore} name="rate1" value={this.state["rate1"]} title={d.rate1} />
                                        <TrStarMobile setRateScore={this.setRateScore} name="rate2" value={this.state["rate2"]} title={d.rate2} />
                                        <TrStarMobile setRateScore={this.setRateScore} name="rate3" value={this.state["rate3"]} title={d.rate3} />
                                        <TrStarMobile setRateScore={this.setRateScore} name="rate4" value={this.state["rate4"]} title={d.rate4} />
                                        <TrStarMobile setRateScore={this.setRateScore} name="rate5" value={this.state["rate5"]} title={d.rate5} />
                                        <TrStarMobile setRateScore={this.setRateScore} name="rate6" value={this.state["rate6"]} title={d.rate6} />
                                        <TrStarMobile setRateScore={this.setRateScore} name="rate7" value={this.state["rate7"]} title={d.rate7} />
                                        <TrStarMobile setRateScore={this.setRateScore} name="rate8" value={this.state["rate8"]} title={d.rate8} />
                                    </tbody>
                                </table>
                            </MediaQuery>
                            <span style={{ fontWeight: 'bold', fontSize: 20 }}>{d.suggestion}</span>
                            <br />
                            <br />


                            <TextArea handleInput={this.handleInput} label={d.food} name="sugFood" readOnly={readOnly} value={this.state} />
                            <TextArea handleInput={this.handleInput} label={d.beverage} name="sugBeverage" readOnly={readOnly} value={this.state} />
                            <TextArea handleInput={this.handleInput} label={d.other} name="sugOther" readOnly={readOnly} value={this.state} />

                            <br />
                            {viewTx.order && viewTx.order.items &&
                                <MenuTable order={viewTx.order} table={table || ""} />
                            }

                            {!readOnly &&
                                <button type="button"
                                    onClick={() => {
                                        //VALIDATE
                                        if (this.validateBirthDate()) {
                                            return
                                        }
                                        swal({
                                            title: "Submit",
                                            text: "Press confirm to submit the Data",
                                            icon: "warning",
                                            buttons: true,
                                        })
                                            .then((submit) => {
                                                if (submit) {
                                                    this.submit()
                                                }
                                            })
                                    }} className="btn btn-primary btn-lg btn-block">
                                    SUBMIT
                            </button>
                            }

                            <br />
                            <br />
                            <br />
                        </div>
                    </div >
                </div>
            </div>
        )
    }
    setRateScore = (name, score) => {
        const { readOnly } = this.state
        if (readOnly) {
            return
        }

        this.setState({
            [name]: score
        })
    }
    validateBirthDate = () => {
        const state = _.cloneDeep(this.state)
        let birthDate = state.birthDate.split('/')

        //START BIRTH DATE
        if (state.birthDate === '') {
            return false
        }

        if (birthDate[0] === '') {
            swal("เกิดข้อผิดพลาด", 'Please fill your Birth Date', "error");
            return true
        }
        if (_.isNaN(parseInt(birthDate[0])) || _.isNaN(parseInt(birthDate[1])) || _.isNaN(parseInt(birthDate[2]))) {
            swal("เกิดข้อผิดพลาด", 'Wrong Birth Date Format', "error");
            return true
        }

        if (parseInt(birthDate[2]) < 1000) { //YEAR
            swal("เกิดข้อผิดพลาด", 'Wrong Birth Date Format (YEAR)', "error");
            return true
        }

        if (parseInt(birthDate[1]) < 1 || parseInt(birthDate[1]) > 12) { //MONTH
            swal("เกิดข้อผิดพลาด", 'Wrong Birth Date Format (MONTH)', "error");
            return true
        }

        const totalDaysInMonth = moment(`${birthDate[2]}-${birthDate[1]}`, "YYYY-MM").daysInMonth()
        if (parseInt(birthDate[0]) > totalDaysInMonth) {  //DAY
            swal("เกิดข้อผิดพลาด", 'Wrong Birth Date Format (DAY)', "error");
            return true
        }
        //END BIRTH DATE

        // //
        // if (!checkEmptyString(state.name) || !checkEmptyString(state.mobile.inputNumber) || !checkEmptyString(state.email)) {
        //     swal("เกิดข้อผิดพลาด", 'Please fill all required informations', "error");
        //     return true
        // }

        // //
        // if (!state.mobile.status) {
        //     swal("เกิดข้อผิดพลาด", 'Wrong phone number format', "error");
        //     return true
        // }

        return false
    }
    submit = () => {
        const state = this.state
        const { user, history, match } = this.props
        const { refId, table, orderId, mode } = match.params

        //Birthdate
        let birthDate = state.birthDate.split('/')
        let year = parseInt(birthDate[2])
        if (year > 2300) {
            year = year - 543
        }
        birthDate = moment(year.toString() + "-" + birthDate[1] + "-" + (parseInt(birthDate[0]) + 1), 'YYYY-MM-DD').toISOString()

        let data = {
            // OrderId: randomShopId,
            name: checkEmptyString(state.name) || '-',
            mobile: checkEmptyString(state.mobile) || '-',
            nationality: checkEmptyString(state.nationality) || '-',
            gender: state.gender,
            birthDate: checkEmptyString(birthDate) || '-',
            email: checkEmptyString(state.email) || '-',
            sugFood: checkEmptyString(state.sugFood) || '-',
            sugBeverage: checkEmptyString(state.sugBeverage) || '-',
            sugOther: checkEmptyString(state.sugOther) || '-',
            rate1: state.rate1,
            rate2: state.rate2,
            rate3: state.rate3,
            rate4: state.rate4,
            rate5: state.rate5,
            rate6: state.rate6,
            rate7: state.rate7,
            rate8: state.rate8,
            table: table
        }

        var params = {
            TableName: 'CustomerSatisfactionTx',
            Key: {
                customerId: user.customerId,
                refId: refId.toString()
            },
            UpdateExpression: 'set #data = :data,#timestamp = :timestamp',
            ExpressionAttributeNames: {
                '#data': 'data',
                '#timestamp': 'timestamp',
            },
            ExpressionAttributeValues: {
                ':data': data,
                ':timestamp': moment().toISOString(),
            }
        };

        if (mode === 'Manual') {
            params.ExpressionAttributeValues[':order'] = { orderId: orderId, isManual: true }
            params.UpdateExpression += ',#order = :order'
            params.ExpressionAttributeNames['#order'] = 'order'
        }

        documentClient.update(params, (err, data) => {
            if (err) {
                swal("เกิดข้อผิดพลาด", err, "error");
                return
            }

            history.push('/thankyou')
        })

    }

    // deleteUnEvalueateData = () => {
    //     const { user } = this.props
    //     fetchTrashData(user)
    // }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleInputMobile = (status, value, countryData, number) => {
        this.setState({
            mobile: {
                status,
                inputNumber: value,
                computedNumber: number,
                countryData
            }
        })
    };
    handleInputNation = (val) => {
        this.setState({
            nationality: val
        })
    }
    handleSelectMobileCountry = (inputNumber, countryData, computedNumber, status) => {

        this.setState({
            mobile: {
                status,
                inputNumber,
                computedNumber,
                countryData
            }
        })
    }

    setGender = (gender) => {
        this.setState({
            gender: gender
        })
    }
}

const checkEmptyString = (val) => {
    if (val === '' || val === null) {
        return false
    }
    return val
}

// const required = () => (
//     <small style={{ color: 'red' }}>*</small>
// )

function Spraed(props) {
    const {
        handleSelectMobileCountry,
        handleInput,
        handleInputMobile,
        readOnly,
        d,
        initKey,
        handleInputNation
    } = props
    return (
        <form>
            <div className="form-row">
                <div className="form-group col-sm-4">
                    <label>{d.name}</label>
                    <input type="text"
                        onChange={handleInput} value={props.name} name="name"
                        className="form-control" disabled={readOnly} />
                </div>
                <div className="form-group col-sm-4">
                    <label >{d.gender}</label>
                    <div style={{ paddingTop: 8 }}>
                        <div className="form-check form-check-inline" style={{ margin: 0 }} >
                            <input
                                disabled={readOnly}
                                className="form-check-input"
                                type="radio"
                                id="radioMale"
                                value="option1"
                                checked={props.gender === 'Male'}
                                onClick={() => props.setGender('Male')}
                            />
                            <label className="form-check-label" for="radioMale">
                                {d.male}
                            </label>
                        </div>
                        <div className="form-check form-check-inline" style={{ margin: 0, marginLeft: 3 }}>
                            <input
                                disabled={readOnly}
                                className="form-check-input"
                                type="radio"
                                id="radioFemale"
                                checked={props.gender === 'Female'}
                                onClick={() => props.setGender('Female')}
                                value="option2"
                            />
                            <label className="form-check-label" for="radioFemale">
                                {d.female}
                            </label>
                        </div>
                    </div>
                </div>

                <div className="form-group col-md-4 col-sm-4">
                    <label >{d.email}</label>
                    <input disabled={readOnly} value={props.email} className="form-control" name="email" onChange={handleInput}  />
                </div>

            </div>

            <div className="form-row">
                <div className="form-group col-md-4 col-sm-4">
                    <div><label>{d.mobile}</label></div>
                    <input
                        onChange={handleInput} value={props.mobile} name="mobile"
                        type="number"
                        className="form-control"  disabled={readOnly} />
                    {/* <IntlTelInput
                        defaultCountry={props.mobile.countryData.iso2}
                        key={initKey}
                        css={['intl-tel-input', 'form-control']}
                        style={{ width: '100%' }}
                        fieldId='mobile'
                        onSelectFlag={handleSelectMobileCountry}
                        disabled={readOnly}
                        value={props.mobile.inputNumber}
                        onPhoneNumberChange={handleInputMobile}
                        preferredCountries={['th', 'us', 'cn', 'de', 'ru', 'ch', 'hk', 'ph', 'my', 'sg', 'jp', 'au', 'gb',]}
                    /> */}
                </div>
                <div className="form-group col-md-4 col-sm-4">
                    <label >
                        <span style={{ fontSize: '0.7em', marginLeft: 4 }}>{d.birthDate} (DD/MM/YYYY)</span>
                    </label>
                    <InputMask disabled={readOnly} className="form-control" value={props.birthDate} name="birthDate" mask="99/99/9999" maskChar='_' onChange={handleInput} placeholder="DD/MM/YYYY" />
                </div>
                <div className="form-group col-md-4 col-sm-4">
                    <label >{d.nationality}</label>
                    <Autocomplete
                        items={country_list}
                        shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                        getItemValue={item => item.label}
                        renderItem={(item, highlighted) =>
                            <div
                                key={item.id}
                                style={{ backgroundColor: highlighted ? 'lightgray' : 'white' }}
                            >
                                {item.label}
                            </div>
                        }
                        renderInput={
                            function (props) {
                                return <input disabled={readOnly} className="form-control" {...props} />
                            }
                        }
                        value={props.nationality}
                        onChange={(e) => {
                            handleInputNation(e.target.value)
                        }}
                        onSelect={(val) => {
                            handleInputNation(val)
                        }}
                        wrapperStyle={{}} //DON'T DELETE THIS
                    />
                </div>



            </div>
        </form>)
}


function TrStarMobile({ setRateScore, name, value, title }) {
    return (
        <tr>
            <td >
                <p >
                    {title}
                </p>
                <div className="row m-0">
                    <div>
                        {_.times(5, i => <span key={`star${i}`} className={`fa fa-star ${value >= (i + 1) ? 'checked' : ''}`} onClick={() => setRateScore(name, i + 1)} />)}
                    </div>
                    <div className="align-self-center">
                        <span style={{ paddingLeft: 15, marginTop: -4 }}><small style={{ color: 'grey', fontSize: '0.85em', fontStyle: 'italic' }}>{getScoreName(value)}</small></span>
                    </div>
                </div>
            </td>

        </tr>
    );
}
function TrStar({ setRateScore, name, value, title }) {
    return (
        <tr>
            <td style={{ width: '35%' }}>
                <label >
                    {title}
                </label>
            </td>
            <td style={{ width: '65%' }}>
                {/* <span className="fa fa-star checked" /> */}
                <div className="row m-0">
                    <div>
                        {_.times(5, i => <span key={`star${i}`} className={`fa fa-star ${value >= (i + 1) ? 'checked' : ''}`} onClick={() => setRateScore(name, i + 1)} />)}
                    </div>
                    <div className="align-self-center">
                        <span style={{ paddingLeft: 15, marginTop: -4 }}><small style={{ color: 'grey', fontSize: '0.85em', fontStyle: 'italic' }}>{getScoreName(value)}</small></span>
                    </div>
                </div>
            </td>
        </tr>
    );
}

const getScoreName = (value) => {
    switch (value) {
        case 1:
            return 'Poor'
        case 2:
            return 'Fair'
        case 3:
            return 'Good'
        case 4:
            return 'Very Good'
        case 5:
            return 'Excellent'
    }
}

function TextArea({ label, hint, readOnly, value, name, handleInput }) {
    return (
        <div className="form-group row">
            <label className="col-sm-4 col-form-label">
                {label || "Field"}
            </label>
            <div className="col-sm-8">
                <textarea
                    disabled={readOnly}
                    value={value[name]}
                    name={name}
                    onChange={handleInput}
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    // placeholder={hint}
                />
            </div>
        </div>
    );
}

const fetchTrashData = async (user) => {
    let params = {
        TableName: "CustomerSatisfactionTx",
        IndexName: 'customerId-index',
        KeyConditionExpression: "customerId = :customerId",
        FilterExpression: 'attribute_not_exists(#dataAttr) and #timestamp < :today',
        ExpressionAttributeValues: {
            ":customerId": user.customerId,
            ':today': moment().startOf('day').toISOString(),
        },
        ExpressionAttributeNames: {
            '#timestamp': 'timestamp',
            '#dataAttr': 'data',
        },
    };

    await documentClient.query(params, function (err, data) {
        if (err) {
            console.log(err);
        } else {
            if (_.size(data.Items) < 1) {
                return
            }
            deleteTrashData(data.Items, user)
        }
    })

}

const deleteTrashData = (dataToBeDeleted) => {
    _.forEach(dataToBeDeleted, line => {
        let params = {
            TableName: "CustomerSatisfactionTx",
            Key: {
                customerId: line.customerId,
                refId: line.refId
            }
        };

        documentClient.delete(params, function (err, data) {
            if (err) {
                console.log(err);
            } else {
                console.log(data);
            }
        });
    })
}


const mapStateToProps = (state) => {
    return {
        viewTx: state.operations.viewTX,
        user: state.user,
        language: state.operations.language
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ ...operationActions }, dispatch)
    return {
        actions,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SatisfyForm)