import swal from 'sweetalert'
import documentClient from '../aws/dynamoDb'
import _ from 'lodash'

export const logInDynamo = (forceUserData, cb, isRedirect) => {

    // let oldKey = window.localStorage.getItem("logInToken");

    let oldUserData = {
        username: window.localStorage.getItem("username"),
        password: window.localStorage.getItem("password")
    }

    let user = forceUserData || oldUserData

    if (user.username === null) {
        window.location.href = '/logIn'
        return
    }

    let param = {
        TableName: "CustomerSatisfactionUsers",
        KeyConditionExpression: "username = :username",
        FilterExpression: 'password = :password',
        ExpressionAttributeValues: {
            ":username": user.username,
            ":password": user.password
        },
        // ExpressionAttributeNames: {
        //     '#token': 'token',
        // },
    };

    documentClient.query(param, (err, data) => {
        if (err) {
            swal('เกิดข้อผิดพลาด', err.message, 'error')
            return
        } else {

            if (_.size(data.Items) < 1) {
                swal('เกิดข้อผิดพลาด', 'คุณกรอก Username หรือ Password ผิดพลาด หรือบัญชีนี้ยังไม่ถูกเปิดใช้งาน', 'error')
                    .then(() => {
                        logoutDynamo(isRedirect)
                    })
                return
            }

            let account = data.Items[0]
            var userData = {
                customerId: account.customerId,
                shopName: account.shopName,
                username: user.username,
                isManager: account.isManager || false,
                businessMode: 1
            };

            // window.localStorage.setItem('logInToken', key)
            window.localStorage.setItem('username', user.username)
            window.localStorage.setItem('password', user.password)

            cb && cb(userData)
        }
    });
}
export const logoutDynamo = (isRedirect) => {
    window.localStorage.removeItem("username");
    window.localStorage.removeItem("password");
    if (isRedirect) {
        window.location.href = '/'
    }
}