import React from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

import { Calendar } from 'react-date-range';
class DatePickerModal extends React.Component {

    state = {
        date: new Date(),
    }

    pickDates = (date) => {

        this.setState({
            date,
        })

    }

    render() {
        const { date } = this.state
        const { saveDates } = this.props

        return (
            <div class="modal fade bs-example-modal-center" tabindex="-1" role="dialog"
                aria-labelledby="mySmallModalLabel" id="myModal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">Pick Date</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">x</button>
                        </div>
                        {/* <div class="modal-body">
                        </div> */}
                        <Calendar
                            date={date}
                            onChange={this.pickDates}
                        />


                        <div class="modal-footer">
                            <button type="button" class="btn btn-success"
                                onClick={() => {
                                    saveDates(date)
                                    window.$('#myModal').modal('toggle');
                                }}
                            // data-dismiss="modal"
                            >Okay</button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default (DatePickerModal)